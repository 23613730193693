import "./scss/plugins/_swiper.scss";

import Swiper, { Navigation, Pagination, EffectFade, Autoplay } from "swiper";
initSwiper({
  modules: [Navigation, Pagination, EffectFade, Autoplay],
  selector: '[data-swiper-hero]',
  slidesPerView: 1,
  spaceBetween: 0,
  effect: 'fade',
  breakpoints: false,
  autoplay: {
    delay: 3000,
  },
  pagination: {
    el: "[data-swiper-pagination]",
    clickable: true,
    renderBullet: function (index, className) {
      return bulletText(this, index, className)
    },
  },
  on: {
    init: function () {
      updateSwiperColor(this);
    },
    slideChange: function () {
      updateSwiperColor(this);
    }
  },
})

const sliderIds = document.querySelectorAll('[data-swiper-id]')
sliderIds.forEach(slider => {
  const slidesPerView = slider.getAttribute("data-slides-per-view") ?? 5;
  let slidesPerViewTablet = 1;
  if (slidesPerView >= 3) {
    slidesPerViewTablet = Math.round(slidesPerView / 2);
  }
  const slidesPerViewMobile = slidesPerView > 4 ? 2 : 1;
  const swiperId = slider.getAttribute('data-swiper-id');
  const loop = slider.hasAttribute('data-swiper-loop');

  initSwiper(
    {
      selector: `[data-swiper-id="${swiperId}"] [data-swiper-posts]`,
      pagination: {
        el: `[data-swiper-id="${swiperId}"] [data-swiper-pagination]`,
        type: "fraction",
        renderFraction: function (current, total) {
          return `<span class="${current}"></span><span class='text-separator'></span><span class="${total}"></span>`;
        }
      },
      slidesPerView: slidesPerViewMobile,
      navigation: {
        nextEl: `[data-swiper-id="${swiperId}"] [data-swiper-button-next]`,
        prevEl: `[data-swiper-id="${swiperId}"] [data-swiper-button-prev]`,
      },
      loop: loop,
      breakpoints: {
        667: {
          slidesPerView: slidesPerViewTablet,
        },
        992: {
          slidesPerView: parseInt(slidesPerView),
        },
      },
    })
})


function initSwiper(options) {
  const defaultOptions = {
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    spaceBetween: 20,
    loop: true,
    loopedSlides: 20,
    autoHeight: false,
    pagination: {
      el: "[data-swiper-pagination]",
      type: "fraction",
    },
    navigation: {
      nextEl: "[data-swiper-button-next]",
      prevEl: "[data-swiper-button-prev]",
    },
  }

  const config = { ...defaultOptions, ...options }

  const sliders = document.querySelectorAll(config.selector);
  if (sliders.length) {
    sliders.forEach((slider) => {
      new Swiper(slider, {
        ...config,
      });
    });
  }
}

function updateSwiperColor(swiper) {
  const activeSlide = swiper.slides[swiper.activeIndex];
  const isWhiteText = activeSlide.dataset.slideColor === 'text-white';

  if (isWhiteText) {
    swiper.el.classList.add('text-white');
  } else {
    swiper.el.classList.remove('text-white');
  }
}

function bulletText(swiper, index, className) {
  const dataSlideTitle = "data-slide-title";
  const slideTitles = swiper.el.querySelectorAll(`[${dataSlideTitle}]`);
  let slideTitlesFormatted = [];

  if (slideTitles)
    slideTitles.forEach((title) => {
      const slideTitleDataText = title.getAttribute(dataSlideTitle);
      slideTitlesFormatted.push(slideTitleDataText);
    });

  if (slideTitlesFormatted)
    return `<button type='button' class="${className}">${slideTitlesFormatted[index]}</button>`;
}